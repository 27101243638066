<script setup lang="ts">
const props = defineProps<{
  path: string
  filter?: string
}>()

const el = ref<HTMLElement | null>(null)

const content = await getContent(props.path, props.filter)

handleClicks(el)
</script>

<script lang="ts" aria-description="utils">
// eslint-disable-next-line import/first
import type { MarkdownNode, ParsedContent } from '@nuxt/content/types'

const emptyContent: ParsedContent = {
  _type: 'markdown',
  _id: '',
  _empty: false,
  description: '',
  body: {
    type: 'root',
    children: [],
  },
}

/**
 * Loads and returns an article or a section of an article
 */
async function getContent(url: string, filter: string = ''): Promise<ParsedContent> {
  // handle filter
  if (filter && !url.startsWith(filter)) {
    return makeContent(emptyContent, 'Content does not match filter')
  }

  const path = filter
    ? url.replace(filter, '')
    : url

  // debug
  // console.log('Fetching:', url)

  // get content
  const [article, section] = path.split('#')
  const content = await (queryContent(article).findOne()).catch((err) => {
    console.warn('queryContent() error:', err)
  })

  // article not found
  if (!content) {
    return makeContent(emptyContent, `Invalid article path "${article}"`)
  }

  // return full article
  if (!section) {
    return content!
  }

  // return section only
  let start = null
  let end = null

  if (content.body?.children && content.body?.children.length > 0) {
    for (const [index, child] of content.body?.children.entries()) {
      if ((child.tag === 'h2' || child.tag === 'h3') && child.props?.id === section) {
        start = index
      }

      if (start && child.tag === 'h2') {
        end = index
      }
    }
  }
  else {
    return makeContent(emptyContent, 'Empty document')
  }

  // section found
  if (start) {
    return makeContent(content, content.body.children.slice(start, end || undefined))
  }

  // section not found
  const ids = content.body.children.values().map(child => child.props?.id)

  // eslint-disable-next-line no-console
  console.info(`The article "${article}" contains only these headings:`, ids)
  return makeContent(content, `The section "${section}" was not found in this article (see console for details).`)
}

/**
 * Walk through the Markdown nodes and fix the following issues with links:
 * - change anchor links to absolute links
 * - add target="_blank"
 */
function walk(nodes: MarkdownNode[], path: string) {
  for (const node of nodes) {
    if (node.type === 'element' && node.tag === 'a') {
      // fix anchor links
      const href = node.props?.href?.split('#')[0]
      if (path.includes(href)) {
        node.props!.href = `${path}#${node.props!.href?.split('#')[1]}`
      }

      // add target="_blank"
      if (node.props?.target !== '_blank') {
        node.props = {
          ...node.props,
          target: '_blank',
        }
      }
    }
    if (node.children) {
      walk(node.children, path)
    }
  }
  return nodes
}

function makeContent(content: ParsedContent, newBody: string | MarkdownNode[]): ParsedContent {
  let children: MarkdownNode[]
  if (typeof newBody === 'string') {
    children = [
      {
        type: 'element',
        tag: 'p',
        props: {},
        children: [
          { type: 'text', value: newBody },
        ],
      },
    ]
  }
  else {
    children = newBody
  }

  return {
    ...content,
    _path: `/pathfinder/articles${content._path}`,
    body: {
      type: 'root',
      children: walk(children, `/pathfinder/articles${content._path}`),
    },
  }
}

/**
 * Prevent links opening in the same page
 */
function handleClicks(el: Ref<HTMLElement | null>) {
  const onClick = (event: MouseEvent) => {
    const target = event.target as HTMLAnchorElement
    if (target.getAttribute('target') !== '_blank') {
      event.preventDefault()
    }
  }

  onMounted(() => {
    el.value?.addEventListener('click', onClick)
  })

  onUnmounted(() => {
    el.value?.removeEventListener('click', onClick)
  })
}
</script>

<template>
  <article ref="el" data-ui="UiContent" class="nuxt-content">
    <ContentRenderer v-if="content" :value="content">
      <ContentRendererMarkdown :value="content" />
    </ContentRenderer>
  </article>
</template>
